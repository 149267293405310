<template>
   <layout-div>
      <div class="row justify-content-md-center">
        <div class="col-12">
            <nav class="navbar navbar-expand-lg navbar-light bg-light">
                <div class="container-fluid">
                    <a class="navbar-brand" href="#">Dashboard</a>
                    <div class="d-flex">
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <a @click="logoutAction()" class="nav-link " aria-current="page" href="#">Logout</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <h2 class="text-center mt-5">Bienvenido, {{user?.name}}!</h2  >
        </div>
      </div>
      <ModalForm  @llamar-mostrar-items="llamarMostrarItems"  />
       <button class="btn btn-success" @click="exportarExcel">Exportar</button>
      <NotasVenta ref="notasVenta"  />
   </layout-div>
</template>
<style>
.modal {
  --bs-modal-width: 700px;
}
.bold-id {
  font-weight: bold;
}

.display-block {
  display: block !important; /* Asegura que display: block tenga prioridad */
}
</style>
  
<script>
import axios from 'axios';
import LayoutDiv from '../LayoutDiv.vue';
import NotasVenta from '../NotasVenta.vue';
import ModalForm from '../ModalForm.vue';

  
export default {
  name: 'DashboardPage',
  components: {
    LayoutDiv,
    NotasVenta,
    ModalForm
  },
  data() {
    return {
      user: {},
    };
  },
  created() {
    this.getUser();
    if(localStorage.getItem('token') == "" || localStorage.getItem('token') == null){
      this.$router.push('/')
    }else {
      this.getUser();
    }
 
  },
  methods: {
       llamarMostrarItems() {
      this.$refs.notasVenta.consultarItems();
    },
    getUser() {
        axios.get('/api/user', { headers:{Authorization: 'Bearer ' + localStorage.getItem('token')}})
        .then((r) => {
           this.user = r.data.user;
           return r
        })
        .catch((e) => {
           return e
        });
    },
    exportarExcel() {
      axios.get('/api/excel', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        responseType: 'arraybuffer'  // Para recibir datos binarios (Excel)
      })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'datos.xlsx';  // Nombre del archivo Excel
        link.click();
      })
      .catch(error => {
        console.error('Error al exportar a Excel', error);
      });
    },
 
    logoutAction () {
     
      axios.post('/api/logout',{}, { headers:{Authorization: 'Bearer ' + localStorage.getItem('token')}})
      .then((r) => {
          localStorage.setItem('token', "")
          this.$router.push('/')
          return r
      })
      .catch((e) => {
       // alert(JSON.stringify(e))
          localStorage.setItem('token', "")
          this.$router.push('/')
        return e
      });
    }
 
  },
};
</script>