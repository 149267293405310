<template>
  <div>
    <h1>{{title}}</h1>
    <table class="table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Fecha</th>
          <th>Total</th>
          <th>Customer</th>
          <th>Tipo</th>
           <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="nota in notas" :key="nota.id" @click="mostrarItems(nota)">
          <td :class="{ 'bold-id': nota.note_items.length > 0 }">{{ nota.id }}</td>
          <td>{{ nota.date }}</td>
          <td>{{ nota.total }}</td>
          <td>{{ nota.customer.name }}</td>
          <td>{{ nota.codigo_tipo_comprobante }}</td>
           <td><button @click="eliminarNota($event, nota.id)">X</button></td>
        </tr>
      </tbody>
    </table>

    <!-- Modal para mostrar los ítems de la nota de venta seleccionada -->
 
    <div class="modal" v-if="itemsNota !== null" :class="{ 'display-block': itemsNota !== null }">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Ítems de la Nota de Venta #{{ itemsNota.id }}</h5>
            <button type="button" class="btn-close" @click="cerrarModal"></button>
          </div>
          <div class="modal-body">
            <table class="table">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>SKU</th>
                  <th>Precio</th>
                  <th>Cantidad</th>
                  <th>Total</th>
                  <th>Action</th>
                
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in itemsNota.note_items" :key="item.id">
                  <td>{{ item.item.name }}</td>
                  <td>{{ item.item.sku }}</td>
                  <td>{{ item.item.price }}</td>
                   <td>{{ item.quantity }}</td>
                  <td>{{ item.total }}</td>
                   <td><button @click="eliminarItem(item.id)">X</button></td>
                  
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      notas: [], // Aquí almacenarás las notas de venta
      itemsNota: null,
      title:'Pedido' // Aquí almacenarás los ítems de la nota de venta seleccionada
    };
  },
  mounted() {
    // Aquí cargarías las notas de venta desde tu backend cuando se carga el componente
    // Puedes hacer una solicitud HTTP utilizando Axios o la biblioteca que prefieras
    // Ejemplo con Axios:
  axios.get('/api/notas-venta').then(response => {
      this.notas = response.data.notas;
   });
    // En este ejemplo, las notas de venta se deben cargar desde tu backend y asignarlas a la variable `notas`
  },
  methods: {
    mostrarItems(nota) {
      // Cuando se hace clic en una fila, se asigna la nota de venta seleccionada y se muestran los ítems
      this.itemsNota = nota;
     
      console.log(this.itemsNota);
    },
    consultarItems(){
       axios.get('/api/notas-venta').then(response => {
      this.notas = response.data.notas;
   });
    },
    cerrarModal() {
      // Al cerrar el modal, se limpia la variable que almacena los ítems de la nota de venta seleccionada
      this.itemsNota = null;
    },
    eliminarItem(id){
      axios.post('/api/note-item/'+id).then(response => {
      //this.notas = response.data.notas;
      console.log(response)
      if(response.data.status=='OK'){
        console.log('entra');
          console.log(this.itemsNota);
            this.itemsNota.note_items= this.itemsNota.note_items.filter(item => item.id !== id);
          
      }
   });

    },
    eliminarNota(evt,id){
      evt.stopPropagation();
     
      axios.post('/api/notas-venta/'+id).then(response => {
      //this.notas = response.data.notas;
      console.log(response)
      if(response.data.status=='OK'){
        console.log('entra');
         
            this.notas= this.notas.filter(item => item.id !== id);
          
      }
   });

    }
  }
};
</script>
