<template>
  <div>
    <button class="btn btn-primary" @click="mostrarModal">Agregar Cliente</button>
    <div class="modal fade" :class="{ 'show': mostrarModalCliente, 'd-block': mostrarModalCliente }" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Agregar Cliente</h5>
            <button type="button" class="btn-close" @click="cerrarModal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div v-if="mostrarModalCliente" class="modal-body">
            <form @submit.prevent="guardarCliente">
              <div class="form-group">
                <label for="tipoCliente">Tipo de Cliente:</label>
                <select v-model="cliente.tipo_cliente_id" class="form-control" id="tipoCliente">
                  <option v-for="rubro in tiposCliente" :key="rubro.id" :value="rubro.id">{{ rubro.name }}</option>
                </select>
              </div>
              <div class="form-group">
                <label for="nombre">Nombre:</label>
                <input v-model="cliente.name" type="text" class="form-control" id="nombre" required>
              </div>
              <div class="form-group">
                <label for="documento">DNI/ RUC:</label>
                <input v-model="cliente.no_documento" type="text" class="form-control" id="documento">
              </div>
              <div class="form-group">
                <label for="email">Correo Electrónico:</label>
                <input v-model="cliente.email" type="email" class="form-control" id="email">
              </div>
              <div class="form-group">
                <label for="telefono">Teléfono:</label>
                <input v-model="cliente.phone" type="text" class="form-control" id="telefono">
              </div>
              <div class="form-group">
                <label for="direccion">Dirección:</label>
                <input v-model="cliente.address" type="text" class="form-control" id="direccion">
              </div>
              <div class="form-group">
                <label for="tipoRubro">Tipo de Rubro:</label>
                <select v-model="cliente.tipo_rubro_id" class="form-control" id="tipoRubro">
                  <option v-for="rubro in tiposRubro" :key="rubro.id" :value="rubro.id">{{ rubro.name }}</option>
                </select>
              </div>
              <div class="form-group">
                <label for="mercado">Mercado:</label>
                <MySearchInput v-model="cliente.mercado" :api-endpoint="'/api/mercados/search'" item-text-key="name" identifier="mercadoSearch" @complete="handleComplete" />
              </div>
              <div class="form-group">
                <label for="puesto">Puesto:</label>
                <input v-model="cliente.puesto" type="text" class="form-control" id="puesto">
              </div>
              <div class="d-flex justify-content-end">
                <button type="submit" class="btn btn-primary mt-3">Registrar</button>
                
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import MySearchInput from '@/components/MySearchInput.vue';

export default {
  data() {
    return {
      mostrarModalCliente: false,
      cliente: {
        nombre: '',
        email: '',
        telefono: '',
        direccion: '',
        mercado_obj: {},
        mercado: '',
        puesto: ''
      },
      tiposRubro: [],
      tiposCliente: []
    };
  },
  components: {
    MySearchInput
  },
  mounted() {
    this.obtenerTiposRubro();
    this.obtenerTiposCliente();
  },
  methods: {
    mostrarModal() {
      this.mostrarModalCliente = true;
    },
    cerrarModal() {
      this.mostrarModalCliente = false;
    },
    guardarCliente() {
      console.log(this.cliente);
      axios.post('/api/customers/save', this.cliente)
        .then(response => {
          console.log(response);
          this.cliente = {};
        })
        .catch(error => {
          console.log(error);
        });
      this.cerrarModal();
    },
    handleComplete(item, inputIdentifier) {
      const text = item.text;
      const id = item.id;
      console.log('Input identificado por:', inputIdentifier);
      console.log('Texto seleccionado:', text);
      console.log('ID seleccionado:', id);
      if (inputIdentifier === 'mercadoSearch') {
        console.log('mercadoSearch', item);
        this.cliente.mercado_obj = item.data;
      }
    },
    obtenerTiposRubro() {
      axios.get('/api/tipo-rubros')
        .then(response => {
          this.tiposRubro = response.data.registros;
        })
        .catch(error => {
          console.error('Error al obtener los tipos de rubro:', error);
        });
    },
    obtenerTiposCliente() {
      axios.get('/api/tipo-clientes')
        .then(response => {
          this.tiposCliente = response.data.registros;
        })
        .catch(error => {
          console.error('Error al obtener los tipos de cliente:', error);
        });
    }
  }
};
</script>

<style scoped>
.modal {
  display: none;
}

.modal.show {
  display: block;
}

.modal-backdrop {
  position: absolute;
}

.btn-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: .75;
}

@media (max-width: 576px) {
  .modal-dialog {
    margin: 0.5rem;
  }
}
</style>