<template>
  <div>
    <button class="btn btn-primary" @click="mostrarModalAgregarItem">Nuevo pedido</button>
    <div class="modal" :class="{ 'show': mostrarModal, 'd-block': mostrarModal }" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Nuevo pedido</h5>
            <button type="button" class="btn-close" @click="cerrarModal"></button>
          </div>
          <div v-if="mostrarModal" class="modal-body">
            <form @submit.prevent="guardarItemNota">
              <ClienteForm/>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="mercado">Mercado:</label>
                    <MySearchInput v-model="selectedMercado"  :api-endpoint="'/api/mercados/search'" item-text-key="name" identifier="mercadoSearchForm" @complete="handleComplete" />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="puesto">Puesto:</label>
                    <MySearchInput v-model="selectedPuesto" :api-endpoint="apiEndpoint2" item-text-key="name" identifier="puestoSearch" @complete="handleComplete" />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="cliente">Cliente:</label>
                    <MySearchInput v-model="selectedCliente" :api-endpoint="'/api/customers/search'" item-text-key="name" identifier="customerSearch" @complete="handleComplete" />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="direccion">Direccion:</label>
                    <input v-model="note.direccion_pedido" type="text" class="form-control" id="direccion">
                  </div>
                </div>
                 <div class="col-6 col-md-6">
                  <div class="form-group">
                    <label for="tipoDoc">TIPO:</label>
                    <select v-model="note.codigo_tipo_comprobante" class="form-control" id="tipoDoc">
                      <option v-for="unidad in tipo_documentos" :key="unidad.codigo" :value="unidad.codigo">
                        {{ unidad.nombre }}
                      </option>
                    </select>
                  </div>
                </div>

              </div>
              <div class="row align-items-center">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="producto">Producto:</label>
                    <MySearchInput :api-endpoint="'/api/items/search'" ref="searchInput" item-text-key="name" identifier="productSearch" @complete="handleComplete" />
                  </div>
                </div>
                <div class="col-6 col-md-2">
                  <div class="form-group">
                    <label for="unidadMedida">U. Medida:</label>
                    <select v-model="item.unidadMedida" class="form-control" id="unidadMedida">
                      <option v-for="unidad in unidades_medida" :key="unidad.codigo" :value="unidad.codigo">
                        {{ unidad.nombre }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-6 col-md-2">
                  <div class="form-group">
                    <label for="cantidad">Cantidad:</label>
                    <input v-model="item.quantity" type="number" @input="handleCantidadChange" class="form-control" id="cantidad">
                  </div>
                </div>
                <div class="col-6 col-md-2">
                  <div class="form-group">
                    <label for="precioTotal">Precio Total:</label>
                    <input v-model="item.total" type="text" class="form-control" id="precioTotal">
                  </div>
                </div>
                <div class="col-12 d-flex justify-content-end">
                  <button @click="agregarItem" class="btn btn-primary mt-3">Agregar producto al pedido</button>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <button @click="registrar" class="btn btn-success mt-3" style="width:100px">Guardar Pedido</button>
              </div>
              <table class="table mt-3">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>SKU</th>
                    <th>Precio</th>
                    <th>Cantidad</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in itemsNota" :key="item.id">
                    <td>{{ item.name }}</td>
                    <td>{{ item.sku }}</td>
                    <td>{{ item.price }}</td>
                    <td>{{ item.quantity }}</td>
                    <td>{{ item.total }}</td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import MySearchInput from '@/components/MySearchInput.vue';
import ClienteForm from './ClienteForm.vue';

export default {
  data() {
    return {
      mostrarModal: false,
      selectedCliente: '',
      selectedMercado: '',
      selectedPuesto: '',
      items: [],
      itemsNota: [],
      unidades_medida: [],
      tipo_documentos:[],
      item: { quantity: '', total: '' },
      note: { total: 0, estado_pedido_id: 1 },
      mercado_id: "",
      apiEndpoint2: '/api/customers/searchPuesto'
    };
  },
  created() {
    this.listarUnidad();
    this.listarTipoDoc();
  },
  methods: {
    resetear() {
      this.note = {};
      this.itemsNota = [];
    },
    cerrarModal() {
      this.mostrarModal = false;
    },
    guardarItemNota() {},
    mostrarModalAgregarItem() {
      this.mostrarModal = true;
    },
    search(selectedItem) {
      console.log('Búsqueda completada:', selectedItem);
    },
    searchItems(query) {
      console.log(query);
      axios.get(`/api/customers/search?name=${query}`)
        .then(response => {
          this.items = response.data.registros;
          console.log(this.items);
        })
        .catch(error => {
          console.error('Error al buscar elementos:', error);
        });
    },
    handleComplete(item, inputIdentifier) {
      const text = item.text;
      const id = item.id;
      console.log('Input identificado por:', inputIdentifier);
      console.log('Texto seleccionado:', text);
      console.log('ID seleccionado:', id);
      if (inputIdentifier === 'mercadoSearchForm') {
        this.mercado_id = item.id;
        this.selectedMercado = text;
        this.apiEndpoint2 = `/api/customers/searchPuesto?mercado_id=${this.mercado_id}`;
      }
      if (inputIdentifier === 'productSearch') {
        console.log('product');
        this.item = item.data;
        console.log(item)
      }
      if (inputIdentifier === 'customerSearch') {
        console.log('item:--')
       console.log(item)
        this.note.customer_id = item.id;
        this.selectedCliente = text; // Actualiza el valor seleccionado en el padre
      }
      if (inputIdentifier === 'puestoSearch') {
        this.note.customer_id = item.data.data.id;
        this.selectedCliente = item.data.data.name;
       
        this.selectedPuesto=text;
        console.log('puesto:',this.selectedPuesto)
        //this.apiEndpoint2 = `/api/customers/searchPuesto?mercado_id=${this.mercado_id}`;
      }
    },
    agregarItem() {
      this.note.total += this.item.total;
      this.itemsNota.push(this.item);
      this.item = {};
      this.$refs.searchInput.clear();
    },
    handleCantidadChange() {
      console.log('La cantidad ha cambiado:', this.item.quantity);
      this.item.total = this.item.quantity * this.item.price;
    },
    registrar() {
      const data = {
        note: this.note,
        noteItems: this.itemsNota,
      };
      axios.post('/api/notas-venta', data)
        .then(response => {
          console.log(response);
          this.mostrarModal = false;
          this.resetear();
          this.$emit('llamar-mostrar-items');
        })
        .catch(error => {
          console.log(error);
          alert('Ocurrió un error al registrar');
        });
    },
    listarUnidad() {
      axios.get(`/api/unidad-medida`)
        .then(response => {
          this.unidades_medida = response.data;
          if (this.unidades_medida.length > 0) {
            this.item.unidadMedida = this.unidades_medida[this.unidades_medida.length - 1].codigo;
          }
          console.log(this.items);
        })
        .catch(error => {
          console.error('Error al buscar elementos:', error);
        });
    },
    listarTipoDoc() {
      axios.get(`/api/tipo-documento`)
        .then(response => {
          this.tipo_documentos = response.data.registros;
         if (this.tipo_documentos.length > 0) {
            this.note.codigo_tipo_comprobante = this.tipo_documentos[this.tipo_documentos.length - 1].codigo;
          }
          console.log(this.items);
        })
        .catch(error => {
          console.error('Error al buscar elementos:', error);
        });
    }
  },
  components: {
    MySearchInput,
    ClienteForm
  }
};
</script>

<style>
/* Estilos del modal */
</style>
