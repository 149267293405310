<template>
  <div>
    <input type="text" class="form-control" @blur="onBlur" v-model="searchQuery" @input="searchItems">
    <ul v-if="showSuggestions" class="autocomplete-suggestions">
      <li v-for="item in localSuggestions" :key="item.id" @mousedown="selectItem(item)">{{ getItemText(item) }}</li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: ['modelValue', 'apiEndpoint', 'itemTextKey', 'itemIdKey', 'identifier'],
  data() {
    return {
      searchQuery: this.modelValue || '', // Initialize with modelValue
      localSuggestions: [],
      showSuggestions: false
    };
  },
  watch: {
    modelValue(newValue) {
      this.searchQuery = newValue;
      
        this.$emit('value_actual', newValue, this.identifier);
        console.log('value actual:',newValue)
    }
  },
  methods: {
    searchItems() {
       this.$emit('update:modelValue',this.searchQuery);

      let url = this.apiEndpoint;
      var arr = this.apiEndpoint.split('?');
      console.log(url)
if (arr.length > 1 && arr[1] !== '') {
   url = `${this.apiEndpoint}&name=${this.searchQuery}`;
}else{
  url = `${this.apiEndpoint}?name=${this.searchQuery}`;
}

      axios.get(url)
        .then(response => {
          this.localSuggestions = response.data.registros;
          this.showSuggestions = true;
        })
        .catch(error => {
          console.error('Error fetching suggestions:', error);
        });
    },
    selectItem(item) {
      const selectedItem = {
        text: this.getItemText(item),
        id: item.id,
        data: item
      };
      this.$emit('update:modelValue', selectedItem.text);
      this.$emit('complete', selectedItem, this.identifier);
      this.showSuggestions = false;
       this.searchQuery = selectedItem.text; 
    },
    getItemText(item) {
      return item[this.itemTextKey];
    },
    onBlur() {
      setTimeout(() => {
        this.showSuggestions = false;
      }, 100);
    },
    clear() {
      this.searchQuery = '';
      this.localSuggestions = [];
    }
  }
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  cursor: pointer;
  padding: 5px;
  background-color: #f9f9f9;
}

.autocomplete-suggestions {
  position: absolute;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  width: 50%;
}

.autocomplete-suggestions li {
  list-style-type: none;
  cursor: pointer;
  padding: 5px;
}

.autocomplete-suggestions li:hover {
  background-color: #f0f0f0;
}
</style>